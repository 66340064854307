.title{
    font-size: 36px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    color: #333333;
}


.title-3{
    font-size: 24px;
}

.title-4{
    font-size: 18px;
}


.title-thin{
    font-weight: 300;
}

.title--m-8{
    margin-bottom: 8px;
}

.title--m-25{
    margin-bottom: 25px;
}

@media (max-width: 786px) {
    .title {
        font-size: 24px;
    }

    .title-3{
        font-size: 20px;
    }
}