@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  /*border: 5px solid black;*/
  display: flex;
  width: 100%;
  min-height: 100%;
}

body {
  /*border: 5px solid green;*/
  margin: 0;
  width: 100%;
  min-height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

#root {
  /*border: 5px solid blue;*/
  min-height: 100%;
  display: flex;
}

@media (max-width: 795px) {
  body {
    /*border: 5px solid green;*/
    font-size: 12px;
  }
}

.products-page {
  /*padding-top: 100px;*/
  /*border: 5px solid green;*/
}

.products-page__slider {
  /*margin-top: 20px;*/
  margin-bottom: 40px;
  border-radius: 10px;
  overflow: hidden;
}

.products-page__filter {
  margin-bottom: 40px;
}

.products-page__list {
  margin-bottom: 40px;
}


.slide {
    width: 100%;
    /*height: 310px;*/
    background-color: #1e1e1e;
    position: relative;
    background-repeat: repeat-x;
    background-size: contain;
    border-radius: 10px;
}

.slide__spread {
    flex-grow: 1;
}

.slide__bottom {
    position: absolute;
    bottom: 35px;
    left: 40px;
}

.slide__date {
    color: white;
    margin-bottom: 10px;
}

.slide__title {
    color: white;
    z-index: 100;
    font-size: 28px;
    padding: 40px 0 0 40px;
    font-weight: bold;
    max-width: 800px;
    margin-bottom: 10px;
}

.slide__title-text--red {
    background-color: #C2044B;
    padding: 5px;
    border-radius: 5px;
}

.slide__text {
    padding: 40px;
    color: white;
    z-index: 100;
    font-size: 24px;
}

.slide__points {
    font-size: 22px;
    color: white;
    z-index: 100;
    padding: 10px 0 40px 40px;
}

.slide__top {
    left: 40px;
    right: 40px;
    top: 35px;
    position: absolute;
}

.slide__background {
    /*position: absolute;*/
    position: relative;
    width: 100%;
    background-color: #222222;
    opacity: 0.8;
    z-index: 50;
    display: block;
}

.slide__body {
    display: flex;
    height: 100%;
    z-index: 150;
    /*position: relative;*/
    position: absolute;
    top: 0;
    width: 100%;
}

.slide__product {

    width: 50%;
    display: flex;
    flex-direction: column;
}

.slide__product--one {
    width: 100%;
}

@media (max-width: 795px) {
    .slide {
        /*height: 92px;*/
    }

    .slide__title {
        font-size: 12px;
        padding: 15px 0 0 15px;
        margin-bottom: 10px;
    }

    .slide__points {
        font-size: 12px;
        padding: 5px 0 5px 15px;
    }
    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets
    {
        bottom: 2px !important;
    }

    .swiper-pagination-bullet {
        width: 5px !important;
        height: 5px !important;
    }

    .slide__title-text--red {
        background-color: #C2044B;
        padding: 5px;
        border-radius: 5px;
    }
}

.point {
    display: inline-block;
}

.point__body {
    display: flex;
    align-items: center;
}

.point__icon {
    height: 18px;
    width: 18px;
    background-color: #ffde6f;
    display: block;
    color: #333333;
    font-size: 10px;
    font-weight: bold;
    line-height: 0;
    border-radius: 10px;
    position: relative;
}

.point__amount {
    margin-right: 5px;
}

.point__amount--big {
    font-size: 36px;
    font-weight: bold;
    color: #333333;
}

.point__amount--green {
    color: #53B741;
}

.point__icon:after {
    content: "B";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    line-height: 18px;
    text-align: center;
}

.point__icon--big {
    height: 38px;
    width: 38px;
    border-radius: 20px;
}

.point__icon--big:after {
    font-size: 21px;
    line-height: 38px;
}

@media (max-width: 786px) {
    .point__icon {
        border-radius: 12px;
        height: 12px;
        width: 12px;
        font-size: 8px;
    }

    .point__icon::after {
        line-height: 12px;
    }

    .point__icon--big {
        height: 24px;
        width: 24px;
        border-radius: 12px;
    }
    .point__icon--big::after {
        line-height: 24px;
        font-size: 12px;
    }

    .point__amount--big {
        font-size: 18px;
    }


}
.button {
    border: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    color: #146ca7;
    cursor: pointer;
    outline: none;
    transition: background-color 500ms;
}

.button:hover {
    border: 1px solid #0074c2;
    color: #0074c2;
}

.button--block {
    display: block;
    width: 100%;
    font-size: 16px;
}

.button--big {
    padding: 15px 20px;
    font-size: 16px;
}

.button--middle {
    padding: 10px 15px;
    font-size: 14px;
}


.button--primary {
    background-color: #3884be;
    color: white;
}

.button--red {
    background-color: #C2044B;
    color: white;
}


.button--primary:hover {
    background-color: #1E9DF4;
    color: white;
    border: none;
}

.button--red:hover {
    background-color: #ee2e71;
    color: white;
    border: none;
}

.button--primary:active {
    background-color: #146CA7;
    color: white;
    border: none;
}

.button--transparent {
    background: none;
}

.button--primary.button--transparent {
    border: 1px solid #0074C2;
    color: #3884be;
    background: none;
}

.button--primary.button--transparent:hover {
    border: 1px solid #0093F5;
    color: #0093F5;
    background: none;
}

.button--white.button--transparent {
    border: 1px solid white;
    color: white;
}

.button--black.button--transparent {
    border: 1px solid #393834;
    color: #333333;
}

.button__children {
    display: inline-block;
}

.button__text {
    display: inline-block;
}

.button__text-icon {
    margin-left: 5px;
    display: inline-block;
}

.button__wrap {
    display: flex;
    align-items: center;
}

.button:disabled {
    opacity: 0.7;
}

@media (max-width: 795px) {
    .button--middle {
        padding: 5px 5px;
        font-size: 12px;
    }

    .button--big {
        padding: 9px 7px;
        font-size: 14px;
    }
}
.filter {
  display: flex;
  flex-wrap: wrap;
}

.filter__item {
  background-color: #ececec;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  margin-right: 10px;
  /*margin-bottom: 10px;*/
  cursor: pointer;
}

.filter__expander {
  position: relative;
}

.filter__expander-submenu {
  right: auto !important;
  top: 43px !important;
  cursor: pointer;
}

.filter__item:hover {
  background-color: #e2e2e2;
}

.filter__item--active {
  background-color: #3884be;
  color: white;
}

.filter__item--bold {
  background-color: #53B741;
  color: white;
}
.filter__item--bold:hover {
  background-color: #63c44e;
}

.filter__item--bold.filter__item--active:hover {
  background-color: #53B741;
}


.filter__item--active:hover {
  background-color: #3884be;
}


@media (max-width: 786px) {
  .filter__expander-submenu {
    right: 0 !important;
    top: 43px !important;
  }
}

.product-list-item {
  border-radius: 10px;
  overflow: hidden;
}

.product-list-item__image {
  width: 100%;
  display: block;
}

.product-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    align-items: stretch;
}

.product-list__item {
    opacity: 1;
    transition: all 500ms;
}

.product-list__item-enter {
    opacity: 0;
}

.product-list__item-enter-active {
    opacity: 1;
    transition: opacity 500ms;
}

.product-list__item-exit {
    opacity: 1;
}

.product-list__item-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}

@media (max-width: 1100px) {
    .product-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 786px) {
    .product-list {
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

    .products-page__filter {
        margin-bottom: 20px;
    }

    .filter__item {
        padding: 9px 11px;
        font-size: 12px;
        margin-right: 5px;
        margin-bottom: 5px;
        cursor: pointer;
    }

    .products-page__slider {
        margin-bottom: 20px;
    }
}

.cart-page {
  /*padding-top: 140px;*/
}

.cart-page__title{
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: bold;
}

.cart-page__body{
  display: flex;
}

.cart-page__products{
  flex-grow: 1;
}


.cart-page__stick-container{

}

.cart-page__stick-panel{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

@media (max-width: 1050px) {
  .cart-page__body{
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
  }
}
.cart-stick-panel{
    background-color: #ECECEC;
    padding: 30px 40px;
    border-radius: 5px;
    width: 300px;
}

.cart-stick-panel__title{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}

.cart-stick-panel__amount{
    font-size: 36px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 20px;
}

.cart-stick-panel__amount--error{
    color: #C2044B;
}

.cart-stick-panel__text{
  font-size: 16px;
    color: #C2044B;
    font-weight: 500;
    margin: 20px 0;
}

@media (max-width: 786px) {
    .cart-stick-panel {
        width: 100%;
    }
}
.cart-item-list__item{
   margin-bottom: 40px;
}
.cart-item {
    display: flex;

    width: 730px;
    align-items: start;
}

.cart-item__image {
    width: 297px;
    min-width: 297px;
    max-width: 297px;
    margin-right: 30px;
    border-radius: 10px;
}

.cart-item__image img {
    display: block;
    width: 100%;
    border-radius: 10px;
}

.cart-item__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 28px;
}

.cart-item__body {
    display: flex;

    flex-direction: column;
    flex-grow: 1;
}

.cart-item__close:after {
    display: inline-block;
    content: "\00d7"; /* This will render the 'X' */
    font-size: xx-large;
    cursor: pointer;
    line-height: 16px;
}

.cart-item__line {
    display: flex;

    margin-bottom: 20px;
    align-items: start;
}

.cart-item__line:last-child {
    margin-bottom: 0;
}

.cart-item__name {
    width: 100px;
    font-size: 18px;
    font-weight: 500;
}

.cart-item__value {
    font-weight: bold;
    font-size: 18px;
}

@media (max-width: 786px) {
    .cart-item {
        width: 100%;
    }

    .cart-item__image {
        width: 120px;
        min-width: auto;
    }

    .cart-item__title {
        font-size: 16px;
        margin-bottom: 15px;
    }
    .cart-item__value {
        font-size: 14px;
    }

    .cart-item__line {
        margin-bottom: 7px;
    }

    .cart-item__name {
        font-size: 14px;
    }

}
.cart-counter {
  display: flex;
  font-weight: 500;
}

.cart-counter__value {
  margin: 0 10px;
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid #999999;
}

.cart-counter__control {
  cursor: pointer;
  position: relative;

}

.cart-counter__el {
  position: relative;
  z-index: 100;

  width: 20px;
  text-align: center;
   line-height: 20px;
}

.cart-counter__bg {
  height: 20px;
  width: 20px;
  position: absolute;
  background-color: #ececec;
  left: 0px;
  top: 0px;
  border-radius: 10px;
  display: none;
}

.cart-counter__el:hover + .cart-counter__bg {
  display: block;
}

@media (max-width: 786px) {
  .cart-counter {
    padding: 0 10px;
  }
}

.confirmation-order-modal-window {
}

.confirmation-order-modal-window__title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 40px;
}

.confirmation-order-modal-window__body {
  display: flex;
}

.confirmation-order-modal-window__button {
  margin-right: 30px;
}

.modal-window {
  background: #ffffffd4 0 0 no-repeat padding-box;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.modal-window__content {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 5px;
  background-color: white;
  padding: 50px;
  margin: auto;
  cursor: auto;
  position: relative;
}

.modal-window-enter {
  opacity: 0;
  top: 100px;
}

.modal-window-enter-active {
  opacity: 1;
  top: 0;
  transition: opacity 500ms, top 500ms;
}

.modal-window-exit {
  opacity: 1;
  top: 0;
}

.modal-window-exit-active {
  opacity: 0;
  transition: opacity 500ms, top 500ms;
  top: 100px;
}

.product-detail-page {
  /*border: 5px solid red;*/
  display: flex;
  min-height: 100%;
  position: relative;
}

.product-detail-page__sidebar {
  /*border: 5px solid black;*/

  color: white;
  width: 35%;
}

.product-detail-page__description {
  /*border: 5px solid blue;*/
  font-size: 18px;
  padding: 0 50px 50px 100px;
  position: relative;
  width: 65%;
}

.product-detail-page__stick {
  /*border: 5px solid green;*/
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  width: 100%;
  background-size: cover;
  padding-top: 30px;
  background-position: bottom;
  background-repeat: no-repeat;
  overflow: hidden;
  min-height: 435px;
}

.product-detail-page__logo > img {
  /*display: block;*/
  max-width: 100%;
}

.product-detail-page__logo {
  margin-bottom: 40px;
  text-align: center;
}

.product-detail-page__face-value {
  margin-bottom: 20px;
}

.product-detail-page__stick-content {
  padding: 50px 35px 0;
}

.product-detail-page__description > h1 {
  font-size: 36px;
}

.product-detail-page__description > h2 {
  font-size: 24px;
}

.product-detail-page__description > h3 {
  font-size: 18px;
}

.product-detail-page__description > p {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

.product-detail-page__preorder-title{
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
}

.product-detail-page__preorder-button{
  margin-bottom: 15px;
}

.button--white{

}

@media (max-width: 950px) {
  .product-detail-page {
    flex-direction: column;
  }
  .product-detail-page__stick-content {
    padding: 0px 10px 0;
  }

  .product-detail-page__sidebar {
    width: 100%;
  }

  .product-detail-page__description {
    width: 100%;
    padding: 0 0 0 0;
  }

  .product-detail-page__stick {
    height: auto;
    background-position: bottom;
    padding-bottom: 50px;
  }
}

.face-value-selector__body {
  display: flex;
  flex-wrap: wrap;
}

.face-value-selector__title {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
}

.face-value-selector__face-value {
  padding: 10px 15px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 600;
  background-color: #ececec;
  color: #333333;
}

.face-value-selector__face-value--selected {
  background-color: #202634;
  color: white;
  border-color: #202634;
  font-weight: 600;
}

.layout {
  /*border: 5px solid red;*/
  min-height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.layout__header {
  /*border: 5px solid black;*/
  /*height: 100px;*/
  width: 100%;
  /*position: absolute;*/
  z-index: 1000;
  display: flex;
  padding-bottom: 10px;
}

.layout__body {
  /*border: 5px solid green;*/
  width: 100%;
  flex-grow: 1;
  /*padding-top: 20px;*/
}

.layout__footer {
  background-color: #ececec;
}

.layout__container {
  margin: auto;
  max-width: 1200px;
  position: relative;
  width: 100%;
}

.page-enter {
  opacity: 0;
  top: 0;
  width: 100%;
  position: absolute;
  z-index: 100;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  z-index: 50;
}

@media (max-width: 1200px) {
  .layout__container {
    padding: 0 5px;
  }
}

@media (max-width: 1150px) {
}

.header {
    background-color: white;
    display: flex;
    align-items: center;
}

.header__item {
    margin-left: 15px;
}

.header__item:first-child {
    margin-left: 0;
}

.header__item--separator {
    flex-grow: 1;
}

@media (max-width: 786px) {
    .header__item--mob-hide {
        display: none;
    }

    .header__item--mob-show {
        display: block;
    }

    .header__item {
        margin-left: 10px;
    }
}

@media (min-width: 786px) {
    .header__item--mob-show {
        display: none;
    }


}

.logo {
  /*height: 100px;*/
  /*width: 131px;*/
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.nav-bar {
  display: flex;
  align-items: center;
}

.nav-bar__item {
  margin-left: 25px;
}

.nav-bar__item:first-child {
  margin-left: 0;
}

.nav-bar__link {
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  display: block;
  color: #222222;
  white-space: nowrap;
}

.nav-bar__link.active::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #0074c2;
  bottom: -10px;
  display: block;
}

.nav-bar-dropbox-link {
  position: relative;
}

.nav-bar-dropbox-link__dropbox {
  position: absolute;
  border: 1px solid #ececec;
  background-color: white;
  border-radius: 5px;
  z-index: 100;
  box-shadow: 0 3px 6px #00000029;
}

.nav-bar-dropbox-link__header {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  display: block;
  color: #222222;
}

.nav-bar-dropbox-link__link {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  display: block;
  color: #222222;
  white-space: nowrap;
  padding: 10px 20px;
}

.nav-bar-mobile__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 36px;
}

.nav-bar-mobile__item {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.nav-bar-mobile__modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 375px;
    height: 813px;
}

.nav-bar-mobile__link {
    text-align: center;
    margin-bottom: 30px;
}

.nav-bar-mobile__closeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    position: absolute;
    top: 9px;
    right: 21px;
    font-size: 20px;
}
.nav-bar-mobile__closeBtn:after {
    display: inline-block;
    content: "\00d7"; /* This will render the 'X' */
    font-size: xxx-large;
    cursor: pointer;
}

@media (max-width: 375px) and (max-height: 813px) {
    .nav-bar-mobile__modal {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 375px) and (max-height: 813px) {
    .nav-bar-mobile__modal {
        width: 375px;
        height: 100%;
    }
}
.cart-widget {
  background-image: url(/static/media/cart.e2e970a7.svg);
  height: 50px;
  width: 50px;
  border-radius: 100px;
  background-color: #ececec;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.cart-widget__total {
  position: absolute;
  bottom: -10px;
  width: 72px;
  left: -10px;
  text-align: center;
  cursor: auto;
}

.cart-widget__total-text {
  padding: 1px 7px;
  border-radius: 10px;
  white-space: nowrap;
  background-color: #3884be;
  color: white;
  font-weight: 500;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
}

.cart-widget__total-text--overflow {
  background-color: #c2004b;
}

.fade-enter{
  opacity: 0;
  transform:  scale(0);
}
.fade-exit{
  opacity: 1;
  transform:  scale(1);
}
.fade-enter-active{
  opacity: 1;
  transform: scale(1);
}
.fade-exit-active{
  opacity: 0;
  transform:  scale(2);
}
.fade-enter-active,
.fade-exit-active{
  transition: opacity 500ms, transform 500ms;
}
.user-bar {
  display: flex;
  cursor: pointer;
  position: relative;
}

.user-bar__body {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  background-color: white;

  border-radius: 5px;
}

.user-bar__name {
  font-weight: 600;
  padding-bottom: 3px;
  position: relative;
  display: flex;
  align-items: center;
}

.user-bar__name > * {
  display: flex;
}

.user-bar__name > *:first-child {
  margin-right: 3px;
}

.user-bar__balance {
  display: flex;
  font-weight: 500;
}

.user-bar__balance-title {
  color: #878787;
}

.user-bar__balance-value {
  margin-left: 5px;
}

.user-bar__submenu {
  position: absolute;
  top: 17px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ececec;
  z-index: 100;
  right: 0;
  box-shadow: 0 3px 6px #00000029;
}

.user-bar__submenu-link {
  padding: 10px 20px;
  color: #222222;
  font-weight: 600;
  display: block;
  text-decoration: none;
  white-space: nowrap;
}

.user-bar__submenu-link:hover {
  background-color: #ececec;
}

@media (max-width: 786px) {
  .user-bar__submenu--mob-hide {
    display: none;
  }
}

.submenu-enter {
  opacity: 0;
}

.submenu-enter-active {
  opacity: 1;
  transition: opacity 200ms, top 200ms;
}

.submenu-exit {
  opacity: 1;
}

.submenu-exit-active {
  opacity: 0;
  transition: opacity 200ms, top 200ms;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
}

.arrow--right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow--left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.arrow--up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.arrow--down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.footer {
}

.footer__row {
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
}

.footer__link {
    margin-right: 40px;
}

.footer__link > a {
    color: #333333;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
}

.footer__separate {
    flex-grow: 1;
}

.footer__row:first-child {
    border-bottom: 1px solid #cdcdcd;
}

.footer__logo,
.footer__copy {
    color: #868686;
}

@media (max-width: 786px) {
    .footer__link {
        margin-right: 10px;
    }

    .footer__link > a {
        font-size: 12px;
    }

    .footer__row {
        padding: 20px 0;
    }
}
.support-page {
  /*padding-top: 140px;*/
}

.support-page__body {
  display: flex;
}

.support-page__form {
  width: 50%;
  padding-right: 140px;
}

.support-page__contacts {
  width: 50%;
}

.support-page__title {
  margin-bottom: 60px;
}

.support-page__form-title, .support-page__contacts-title {
  margin-bottom: 40px;
}

.support-page__contact-title {
  color: #868686;
  font-size: 12px;
  margin-bottom: 8px;
}

.support-page__contact-value {
  color: #3884BE;
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: bold;
}

.support-page__contact-item {
  margin-bottom: 30px;
}

@media (max-width: 786px) {
  .support-page__body {
    display: flex;
    flex-direction: column-reverse;
  }
  .support-page__form {
    width: 100%;
    padding-right: 0;
  }
  .support-page__form .button {
    width: 100%;
  }
  .support-page__contacts {
    width: 100%;
  }
  .support-page__title {
    margin-bottom: 15px;
  }
 .support-page__form-title, .support-page__contacts-title {
    margin-bottom: 10px;
  }
  .support-page__contact-value {
    font-size: 18px;
  }

  .support-page__contact-item {
    margin-bottom: 15px;
  }
}
.form{
    display: flex;
    flex-direction: column;
}

.form__title{
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 600;
}

@media (max-width: 700px) {
    .form__title {
       font-size: 18px;
    }
}
.form-row{
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

@media (min-width: 321px) and (max-width: 768px) {
    .form-row{
        margin-bottom: 10px;
    }
}
.text-input {
    width: 100%;
    height: 60px;
    position: relative;
    background-color: #ececec;
    border-radius: 5px;
    border: 1px solid white;
}

.text-input--error {
    border: 1px solid red;
}

.text-input--middle {
    height: 50px;
}


.text-input--middle > input {
    height: 25px !important;
    font-size: 16px !important;
}

.text-input--middle > .text-input__label--filled, .text-input--middle > .text-input__label--focus {
    font-size: 12px !important;
    height: 24px !important;
    line-height: 24px !important;
}

.text-input--middle > .text-input__label--cleared {
    font-size: 16px;
    height: 50px;
    line-height: 50px;
}

.text-input > input {
    height: 30px;
    padding: 0 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    width: 100%;
    bottom: 5px;
    outline: none;
    border: none;
    background-color: #ececec;
}

.text-input__label {
    width: 100%;
    position: absolute;
    font-weight: 500;
    color: #868686;
    padding: 0 20px;
    z-index: 50;
    transition: all 100ms;
    cursor: text;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-input__label--cleared {
    font-size: 16px;
    height: 60px;
    line-height: 60px;
}

.text-input__label--filled, .text-input__label--focus {
    font-size: 12px;
    height: 35px;
    line-height: 35px;
}

@media (max-width: 768px) {
    .text-input > input, .text-input__label {
        padding: 0 7px;
    }
    .text-input > input {
        height: 25px;
    }

}

@media (max-width: 320px) {
    .text-input > input {
        font-size: 12px;
    }

    .text-input__label--cleared {
        font-size: 10px;
    }

    .text-input__label--filled, .text-input__label--focus {
        font-size: 9px;
    }

}

@media (min-width: 321px) and (max-width: 768px) {
    .text-input > input {
        font-size: 14px;
        bottom: 2px;
    }

    .text-input {
        height: 40px;
    }

    .text-input__label--filled, .text-input__label--focus {
        font-size: 9px !important;
        height: 14px !important;
        line-height: 14px !important;
    }

    .text-input__label--cleared {
        font-size: 12px;
        height: 40px;
        line-height: 40px;
    }

}
.title{
    font-size: 36px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    color: #333333;
}


.title-3{
    font-size: 24px;
}

.title-4{
    font-size: 18px;
}


.title-thin{
    font-weight: 300;
}

.title--m-8{
    margin-bottom: 8px;
}

.title--m-25{
    margin-bottom: 25px;
}

@media (max-width: 786px) {
    .title {
        font-size: 24px;
    }

    .title-3{
        font-size: 20px;
    }
}
.wave-page {
    /*padding-top: 140px;*/
}

.wave-page__waves{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    align-items: stretch;
    margin-bottom: 40px;
}

.wave-page__title{
    margin-bottom: 25px;
}

.wave-page__text{
    font-size: 20px;
    color:#333333 ;
    font-weight: 500;
    margin-bottom: 40px;
}

.wave-card{
    position: relative;
    background:#000;
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow: hidden;
    border-radius: 10px;
}

.wave-card__background{
    position: absolute;
    width: 100%;
    background-color: #222222;
    opacity:0.4;
    z-index: 50;
}

.wave-card__title{
    color: white;
    z-index: 100;
    font-size: 28px;
    padding: 40px;
    font-weight: bold;
}

.wave-card__separator{
    flex-grow: 1;
}

.wave-card__points{
    display: flex;
    z-index: 100;
    color: white;
    padding: 40px;
}

.wave-card__point-success{
    margin-right: 40px;
}

.wave-card__point-title{
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: 300;
}

.wave-card__point-value{
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 500;
}
.about-products-page {
    /*padding-top: 140px;*/
}

.about-products-page__title {
    margin-bottom: 30px;
}

.about-products-page__title--main {
    margin-bottom: 40px;
}

.about-products-page__text {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 60px;
    max-width: 850px;
}

.about-products-page__section {
    background-color: #F2F4F6;
    padding: 40px 50px;
    margin-bottom: 80px;
}

.about-products-page__section-body {
    display: flex;
    align-items: center;
}

.about-products-page__section-title {
    display: flex; 
    align-items: center;
    margin-bottom: 30px;
}

.about-products-page__section-title img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

.about-products-page__section-content {
    padding-left: 50px;
    width: 50%;
}

.about-products-page__section-video {
    width: 50%;
}

.about-products-page__section-video iframe {
    display: block;
    width: 100%;
}

.about-products-page__section-video-link {
    text-align: center;
    padding-top: 5px;
}

.about-products-page__section-video-link a {
    color: #0f5b99;
    text-decoration: none;
    cursor: pointer;
}

.about-products-page__section-video-link a:hover {
    text-decoration: underline;
}

.html5-video-player a {
    color: inherit;
    text-decoration: none;
    transition: color .1s cubic-bezier(0.0, 0.0, 0.2, 1);
    outline: 1px solid red;
}

@media (max-width: 786px) {
    .about-products-page__section {
        padding: 30px;
    }

    .about-products-page__section-body {
        display: flex;
        flex-direction: column;
    }

    .about-products-page__section-content {
        padding-left: 0;
        width: 100%;
    }
    
    .about-products-page__section-video {
        width: 100%;
        position: relative;
        padding-bottom: 57.25%;
        padding-top: 25px;
        height: 0;
        margin-bottom: 30px;
    }

    .about-products-page__section-video iframe {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .about-products-page__section-video-link {
        padding-top: 57.25%;
    }
}

@media (max-width: 295px) {
    .about-products-page__section-video {
        margin-bottom: 35px;
    }
}
.paragraph{
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 500;
}

.paragraph--think{
    font-weight: 300;
}

.paragraph:last-child{
    margin-bottom: 0;
}
.link{
    transition: all 100ms linear;
}


.link--button{
    color: #222222;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    padding: 10px 20px;
}

.link--button.link--primary{
    background-color: #3884BE;
    color: white;
}

.link--button.link--primary:hover{
    background-color: #1E9DF4;
}

.link--button.link--rounded{
    border-radius: 20px;
}
.thank-for-order-page {
  /*padding-top: 180px;*/
}

.thank-for-order-page__center {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thank-for-order-page__icon {
  width: 200px;
  height: 200px;
  background-image: url(/static/media/good-bag.66a9f26c.svg);
    margin-bottom: 40px;
  background-repeat: no-repeat;
}

.thank-for-order-page__title {
  color: #333333;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.thank-for-order-page__text {
  text-align: center;
  font-size: 18px;
  color: #333333;
  margin-bottom: 40px;
}

.create-wave-page {
  /*padding-top: 140px;*/
}

.create-wave-page__title {
  padding: 0 0 20px 0;
}

.create-wave-page__body {
  display: flex;
}

.create-wave-page__form {
  width: 100%;
}

.create-wave-page__description {
  display: flex;
  flex-direction: column;
  padding-left: 180px;
}

.create-wave-page__description-body {
  margin-bottom: 50px;
}

.create-wave-page__description-title {
  margin-bottom: 10px;
}

.create-wave-page__description-text {
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 786px) {
  .create-wave-page__body {
    display: flex;
    flex-wrap: wrap;
  }

  .create-wave-page__description {
    padding-left: 0;
  }
}
.form-section{

}

.form-section__title{
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 10px;

}

.form-section__body{

}

@media (max-width: 700px) {
    .form-section__title {
        font-size: 18px;
    }
}
.upload-dropzone {
    width: 100%;
}

.upload-dropzone__dropzone {
    border: 2px dashed #CBCBCB;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    padding: 20px;
    outline: none;
    z-index: 50;
    position: relative;
}

.upload-dropzone__body {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-dropzone__text {
    margin-bottom: 10px;
    color: #868686;
    font-size: 18px;
    font-weight: 600;
}

.upload-dropzone__text-small {
    margin-bottom: 20px;
    color: #868686;
    font-size: 12px;
    font-weight: 600;
}

.upload-dropzone__thumbs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    align-items: stretch;
    z-index: 100;
    position: relative;
}

.upload-dropzone__thumb {
    width: 100px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid #CBCBCB;
    background-color: white;
    z-index: 100;
}

.upload-dropzone__thumb img {
    width: 100%;
    display: block;
}

.upload-dropzone__thumb-close {
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 1px 5px;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    cursor: pointer;
    z-index: 100;
}

.upload-dropzone__thumb-enter {
    opacity: 0;
}

.upload-dropzone__thumb-enter-active {
    opacity: 1;
    transition: opacity 500ms;
}

.upload-dropzone__thumb-exit {
    opacity: 1;
}

.upload-dropzone__thumb-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}

@media (max-width: 768px) {
    .upload-dropzone__body {
        width: 100%;
    }

    .create-wave-page__form .upload-dropzone__thumbs {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 900px) {
    .upload-dropzone__body {
        width: 100%;
    }

    .profile-page__ndfl-form .upload-dropzone__thumbs {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 900px) and (max-width: 1200px) {
    .upload-dropzone__body {
        width: 100%;
    }

    .profile-page__ndfl-form .upload-dropzone__thumbs {
        grid-template-columns: repeat(3, 1fr);
    }
}
.data-list {
  position: relative;
  width: 100%;
}

.data-list__text-input {
}

.data-list__data {
  position: absolute;
  width: 100%;
  z-index: 400;
  border: 1px solid #dedede;
  box-shadow: 0 8px 14px #00000029;
  background-color: white;
  top: 55px;
  border-radius: 5px;
}

.data-list__check {
  margin-right: 10px;
}

.data-list__item {
  padding: 10px;
  font-size: 16px;
  color: #2e3546;
  font-weight: 500;
  cursor: pointer;
  display: flex;
}

.data-list__button {
  padding: 20px 0;
  text-align: center;
}

.data-list__item:hover {
  background-color: #efefef;
}

.data-list__data-container {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}

.data-list__data-enter {
  opacity: 0;
}

.data-list__data-enter-active {
  opacity: 1;
  transition: opacity 200ms, top 200ms;
}

.data-list__data-exit {
  opacity: 1;
}

.data-list__data-exit-active {
  opacity: 0;
  transition: opacity 200ms, top 200ms;
}

.table {
    width: 100%;
    border-spacing: 0;
}

.table__header {
    border: 5px solid blue;
}

.table__row {
    border: 5px solid blue;
}

.table__header {
    border: 5px solid green;
}

.table__column {
    color: #333333;
    padding: 10px 20px 10px 0;
    font-size: 16px;
    font-weight: 500;
}

.table__column:first-child{
    padding-left: 10px;
}

.table__column--header {
    font-weight: 500;
    font-size: 14px;
    color: #999999;
}

.table__row--zebra:nth-child(2n+1) {
    background: #F5F5F5;
}
.total__body{
    display: flex;
    font-weight: 500;
    font-size: 16px;
}

.total__text{
    font-weight: 500;
    font-size: 16px;
    margin-right: 10px;
}

.total__value{
    font-weight: bold;
    font-size: 18px;
    margin-right: 10px;

}


.total__hint{
    color: #999999;
    font-size: 12px;
}
.add-sale__error{
    font-size: 16px;
    color: #C2044B;
    font-weight: 500;
    margin: 20px 0;
}

.add-sale__change-dealer-link {
    vertical-align: center;
    margin: auto;
    padding: 0 5px;
}
.alert{
    padding: 30px 40px;
    border-radius: 5px;
    
}

.alert--warning{
    background-color: #FFDE6F;
    color: #333333;
}

.alert .alert-column{

}
.events-page{
    /*padding-top: 140px;*/
}

.events-page__title{
    margin-bottom: 20px;
}

.events-page__filter{
    margin-bottom: 70px;
}

.events-page__item{
    margin-bottom: 70px;
}

@media (max-width: 786px) {
    .events-page__filter {
        margin-bottom: 30px;
    }
}
.button-group {
  display: inline-block;
}

.button-group__body {
  display: flex;
}

.button-group__button {
  padding: 10px 15px;
  color: #222222;
  font-weight: 600;
  margin-right: 1px;
  background-color: #ececec;
  cursor: pointer;
}

.button-group__button:hover {
  background-color: #cccccc;
}

.button-group__button--active {
  background-color: #0074c2;
  color: white;
}

.button-group__button--active:hover {
    background-color: #0074c2;
    color: white;
}

.button-group__button:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.button-group__button:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.order-event {
    display: flex;
    align-items: start;
    cursor: pointer;
}

.order-event__image {
    margin-right: 50px;
    position: relative;
}

.order-event__image:after {
    content: "";
    position: absolute;
    top: -17px;
    left: -22px;
    z-index: 50;
    right: -20px;
    bottom: -16px;
    background-position: center;
    background-size: contain;
    background-image: url(/static/media/csrds-group.8b831cbb.svg);
    background-repeat: no-repeat;

}

.order-event__image img {
    border-radius: 5px;
    display: block;
    width: 187px;
    z-index: 150;
    position: relative;
}

.order-event__body {
    width: 700px;
    padding-right: 100px;
}

.order-event__description {

}

.order-event__description > div {
    margin-bottom: 5px;
}

.order-event__title {
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 20px;
}

.order-event__products {
    display: flex;
    flex-wrap: wrap;

}

.order-event__product {
    display: flex;
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #ECECEC;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
}

.order-event__total {
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 5px;
}

.order-event__status {
    font-size: 16px;
    font-weight: 600;
    color: #53B741;
    margin-bottom: 5px;
}

.order-event__date {
    font-size: 16px;
    font-weight: 600;
    color: #868686;
    margin-bottom: 5px;
    margin-top: 5px;
}

.order-event__product-name {
    white-space: nowrap;
}

.order-event__product-quantity {
    color: #868686;
    margin-left: 10px;
}

@media (max-width: 786px) {
    .order-event__description {
        width: 240px;
        text-align: right;
    }

    .events-page__item {
        margin-bottom: 10px;
    }

    .order-event__date {
        font-size: 9px;
    }

    .order-event__status {
        font-size: 12px;
    }

    .order-event__total {
        font-size: 18px;
    }

    .order-event__body {
        padding-right: 10px;
        width: 500px;
    }

    .order-event__product {
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px;
        font-size: 11px;
        font-weight: 500;
        border-radius: 3px;
    }

    .order-event__title {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .order-event__image {
        margin-right: 20px;
    }

    .order-event__image img {
        border-radius: 3px;
        width: 65px;
    }

    .order-event__image:after {
        top: -6px;
        left: -28px;
        z-index: 50;
        right: -25px;
        bottom: -5px;
    }
}
.order-status {
    font-size: 16px;
    font-weight: 600;
    /*margin-bottom: 5px;*/
}

.order-status--processing {
    color: #0074C2;
}

.order-status--completed {
    color: #53B741;
}

.order-status--rejected {
    color: #C2044B;
}

@media (max-width: 786px) {
    .order-status {
        font-size: 12px;
    }
}
.order-sender-to-email {
    display: flex;
    align-items: center;
}

.order-sender-to-email__img {
    width: 20px;
    margin-right: 5px;
}

.order-sender-to-email__text {
    color: #0074C2;
    cursor: pointer;
}
.confirmation-resend-order-to-email-modal-window {
    max-width: 400px;
    text-align: center;
}

.confirmation-resend-order-to-email-modal-window__title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 40px;
}

.confirmation-resend-order-to-email-modal-window__body {
    /*display: flex;*/
}

.confirmation-resend-order-to-email-modal-window__content {
    margin-bottom: 40px;
}

.confirmation-resend-order-to-email-modal-window__button > button {
    width: 100%;
}

@media (max-width: 786px) {
    .confirmation-resend-order-to-email-modal-window__title {
        font-size: 21px;
    }
}
.increase-balance-event {
    display: flex;
    align-items: start;
}

.increase-balance-event__card-image {
    margin-right: 50px;
}

.card-empty-image {
    width: 187px;
    height: 117px;
    background-color: #53B741;
    border-radius: 10px;
    opacity: 0.5;
    display: flex;
    align-items: center;
    vertical-align: center;
}

.card-empty-image__point-circle {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    background: #fff;
    color: #53B741;
    text-align: center;
    line-height: 75px;
    margin: auto;
    font-size: 36px;
    font-weight: bold;
}


.increase-balance-event__name {
    width: 700px;
    padding-right: 100px;
}

.increase-balance-event__body {
    width: 700px;
    padding-right: 100px;
}

.increase-balance-event__description {
}


.increase-balance-event__title {
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 20px;
}

.increase-balance-event__total {
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 5px;
}

.increase-balance-event__date {
    font-size: 16px;
    font-weight: 600;
    color: #868686;
    margin-bottom: 5px;
}

@media (max-width: 786px) {
    .increase-balance-event__title{
        font-size: 16px;
    }
    .card-empty-image {
        width: 65px;
        height: 40px;
        border-radius: 5px;
    }

    .card-empty-image__point-circle {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin: auto;
        font-size: 10px;
    }

    .increase-balance-event__card-image {
        margin-right: 20px;
        min-width: 65px;
    }

    .increase-balance-event__body {
        width: auto;
        padding-right: 20px;
        flex-grow: 1;
    }

    .increase-balance-event__total {
        font-size: 18px;
    }
    .increase-balance-event__date {
        font-size: 9px;
    }

    .increase-balance-event__description {
        min-width: 89px;
        text-align: right;
    }
}
.profile-page {
    /*padding-top: 140px;*/
}

.profile-page__title {
    margin-bottom: 30px;
}

.profile-page__alert {
    margin-bottom: 20px;
}

.profile-page__forms {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.profile-page__contact-form {
    width: 45%;
    /*padding-right: 50px;*/
}

.profile-page__ndfl-form {
    width: 50%;
    padding-left: 50px;
}

.profile-page__point-form {
    width: 50%;
}
.profile-page__sent {
    padding: 15px 25px;
    font-weight: bold;
    font-size: 18px;
}

@media (max-width: 700px) {
    .profile-page__forms {
        display: flex;
        flex-direction: column;
    }
    .profile-page__contact-form {
        width: 100%;
        padding-right: 0;
    }
    .profile-page__ndfl-form {
        width: 100%;
        padding-left: 0;
    }

    .profile-page__ndfl-form .button {
        width: 100%;
    }
    .profile-page__title {
        margin-bottom: 10px;
        margin-bottom: 10px;
    }
}
.alert-row{
    display: flex;
}

.alert-row--end {
    align-items: flex-end;
}
.flex-separator{
    flex-grow: 1;
}
.select-list {
    width: 100%;
    height: 60px;
    position: relative;
    background-color: #ececec;
    border-radius: 5px;
    border: 1px solid white;
    cursor: pointer;
}

.select-list--disabled {
    cursor: default;
}
.select-list-value {
}

.select-list-value__label {
    color: #868686;
    width: 100%;
    position: absolute;
    font-weight: 500;
    padding: 0 20px;
    z-index: 50;
    transition: all 100ms;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    height: 60px;
    line-height: 60px;
}

.select-list-value__label--selected {
    font-size: 11px;
    height: 35px;
    line-height: 35px;
}

.select-list-value__arrow {
    position: absolute;
    right: 15px;
    top: 20px;
    border-color: #868686;
}

.select-list--opened .select-list-value__arrow {
    top: 25px;
}

.select-list--disabled .select-list-value__arrow  {
    border-color: #868686;
}

.select-list__items {
    background-color: white;
    position: absolute;
    top: 63px;
    z-index: 100;
    width: 100%;
    padding: 10px 0;
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #ececec;
    border-radius: 5px;
    cursor: pointer;
}
.select-list__item {
    line-height: 2;
    padding: 0px 20px;
    font-weight: 500;
    font-size: 18px;
}
.select-list__item:hover {
    background-color: #ececec;
}


.select-list-value__text {
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    cursor: text;
    bottom: 5px;
}

.select-list--disabled .select-list-value__text  {
    color: rgb(84, 84, 84);
}


@media (max-width: 320px) {
    .select-list-value__text {
        font-size: 12px;
    }

    .select-list-value__label {
        font-size: 10px;
    }

    .select-list-value__label--selected {
        font-size: 9px;
    }
}

@media (min-width: 321px) and (max-width: 768px) {
    .select-list-value__text {
        font-size: 14px;
    }

    .select-list-value__label {
        font-size: 12px;
    }

    .select-list-value__label--selected {
        font-size: 11px;
    }
}
.wave-details-page{
    /*padding-top: 120px;*/
    margin-bottom: 100px;
}
.wave-details-page__banner{
    margin-bottom: 50px;
}


.wave-details-page__body{
    display: flex;
    padding-top: 60px;
}

.wave-details-page__grid{
    padding-top: 60px;
}

.wave-details-page__sales{
    width: 50%;
    padding-right: 100px;
}

.wave-details-page__description{
    width: 50%;
}

.wave-details-page__title{

}

.wave-details-page__tabs{
    display: flex;
    font-size: 18px;
}

.wave-details-page__tab{
    margin-right: 40px;
    padding-bottom: 10px;
    cursor: pointer;
    font-size: 24px;
    font-weight: 300;
}

.wave-details-page__tab--active{
    font-weight: 600;
    border-bottom: 3px solid #0074c2;
}

.wave-details-page__table{
    /*width: 600px;*/
}

.wave-details-page__body{

}

.wave-details-page__body img{
    width: 100%;
}

@media (max-width: 320px) {
    .wave-details-page__tab{
        margin-right: 20px;
        font-size: 23px;
    }


}

@media (max-width: 786px) {
    .wave-details-page__tab{
        margin-right: 20px;
        font-size: 16px;
    }

    .wave-details-page__grid {
        padding-top: 20px;
    }

    .products-in-wave__group-name {
        font-size: 16px;
    }



}

.products-in-wave{
  width: 100%;
}

.products-in-wave__head{
    border-radius: 5px;
    position: relative;
    width: 100%;
    background-color: #000000;
    margin-bottom: 25px;
}

.products-in-wave__title{
    font-size: 24px;
    font-weight: bold;
    color: white;
    position: absolute;
    top: 40px;
    left: 30px;
}


.products-in-wave__points{
    position: absolute;
    font-size: 18px;
    bottom: 40px;
    left: 30px;
    color: #333333;
    background-color: #ECECEC;
    padding: 5px 10px;
    font-weight: 600;
    border-radius: 5px;
}

.products-in-wave__head img{
    width: 100%;
    display: block;
    opacity: 0.5;
    border-radius: 5px;
}

.products-in-wave__head--r10, .products-in-wave__head--r10 img{
    border-radius: 10px;
}

.products-in-wave__image{
    width: 100%;
}

.products-in-wave__body{

}

.products-in-wave__group{
    margin-bottom: 25px;
}

.products-in-wave__group-name{
    color: #333333;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
}

.products-in-wave__group-name:hover{
    text-decoration: underline;
    cursor: pointer;
}

.products-in-wave__product-name{
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
}

.products-in-wave__vendor-codes{
    display: flex;
    flex-wrap: wrap;
}

.products-in-wave__vendor-code{
    margin-right: 10px;
    color: #868686;
    font-size: 14px;
}

.products-in-wave__product{
    margin-bottom: 15px;
}

.products-in-wave__group-products{

}

@media (max-width: 360px) {
    .products-in-wave__title {
        font-size: 16px;
    }
}

@media (max-width: 375px) {
    .products-in-wave__title {
        font-size: 18px;
        top: 20px;
        left: 25px;
    }

    .products-in-wave__points {
        left: 25px;
    }
}

@media (max-width: 786px) {
    .products-in-wave__group-name  {
        font-size: 16px;
    }
}


.grid{
    display: grid;
    align-items: stretch;

}

.grid--gap-30{
    grid-gap: 30px;
}

.grid--columns-2{
    grid-template-columns: 1fr 1fr;
}

.grid--columns-3{
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.grid-column{
    width: 100%;

}
.sale-status{
    display: flex;
    align-items: center;
}

.sale-status__icon{
    height: 10px;
    width: 10px;

    margin-right: 5px;
    border-radius: 10px;
}

.sale-status__icon--success{
background-color: #53B741;
}

.sale-status__icon--wait{
    background-color: #868686;
}

.sale-status__icon--rejected{
    background-color: #ffde6f;
}
.sales-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-spacing: 0;
}

.sales-table__header {
    display: flex;
    flex-direction: column;
}

.sales-table__header-row {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    font-size: 14px;
    color: #999999;
}

.sales-table__header-cell {
    padding: 10px 20px 10px 0;
    font-size: 16px;
    font-weight: 500;
}


.sales-table__body {
    display: flex;
    flex-direction: column;
}

.sales-table__body-row {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    font-size: 14px;
    color: #999999;
    cursor: pointer;
}

.sales-table__body-cell {
    color: #333333;
    padding: 10px 20px 10px 0;
    font-size: 16px;
    font-weight: 500;
}
.sales-table__body-cell > .arrow {
    border-color: #3884be;
    margin-right: 5px;
    margin-bottom: 3px;
}

.sales-table__body-cell--blue {
    color: #3884be;
}

.sales-table__sale-products {
    overflow: hidden;  /* Hide the element content, while height = 0 */
    height: 0;
    opacity: 0;
    transition: height 0ms 400ms, opacity 400ms 0ms;
    margin-left: 50px;

}

.sales-table__sale-products--expanded {
    height: auto; opacity: 1;
    transition: height 0ms 0ms, opacity 600ms 0ms;
}


.sales-table__body-cell:first-child, .sales-table__header-cell:first-child {
    padding-left: 10px;
}

.sales-table__body-row--zebra:nth-child(4n+1) {
    background: #F5F5F5;
}
.sale-products-table {
    display: flex;
    flex-direction: column;
}

.sale-products-table__header {

}


.sale-products-table__header-row {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    font-size: 14px;
    color: #999999;
}

.sale-products-table__header-cell {
    padding: 10px 20px 10px 0;
    font-size: 16px;
    font-weight: 500;
}

.sale-products-table__body {
    display: flex;
    flex-direction: column;
}

.sale-products-table__body-row {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    font-size: 14px;
    color: #999999;
}

.sale-products-table__body-cell {
    color: #333333;
    padding: 10px 20px 10px 0;
    font-size: 16px;
    font-weight: 500;
}
.booklets-page {
    /*padding-top: 140px;*/
}

.booklets-page__title {
    margin-bottom: 30px;
}

.booklets {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 960px) {
    .booklets {
        display: flex;
        flex-direction: column;
    }
}

.booklets__item {
    width: 50%;
    margin-bottom: 80px;
}

.booklet {
    display: flex;
    align-items: start;
}

.booklet__image {
    width: 218px;
    background-color: #333333;
}

.booklet__image img {
    display: block;
    width: 218px;
}

.booklet__body {
    padding: 30px;
}

.booklet__title {
    font-size: 24px;
    font-weight: 300;
    color: #333333;
    margin-bottom: 20px;
}

.booklet__text {
    font-size: 16px;
    font-weight: 300;
    color: #333333;
    margin-bottom: 30px;
}

@media (max-width: 480px) {
    .booklet__body {
        padding: 15px;
    }

    .booklet__title {
        font-size: 18px;
    }
    
    .booklet__text {
        font-size: 12px;
    }

    .booklet__image,
    .booklet__image img {
        width: 158px;
    }
}

@media (max-width: 320px) {
    .booklet {
        flex-wrap: wrap;
    }

    .booklet__body {
        padding: 0;
        padding-top: 15px;
    }
}
.confirm-email-page {
    /*padding-top: 140px;*/
}


.wave-products-page {
    /*padding-top: 120px;*/
}

.wave-products-banner {
    background-color: black;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 25px;
    position: relative;
}

.wave-products-banner img {
    border-radius: 10px;
    max-width: 100%;
    max-height: 100%;
    display: block;
    opacity: 0.6;
}

.wave-products-banner__title {
    position: absolute;
    top: 0;
    padding: 40px;
    color: white;
    font-size: 36px;
    font-weight: bold;
}

.wave-products-banner__title--red {
    padding: 10px;
    margin: 30px;
    background-color: #C2044B;
    border-radius: 5px;
}

.wave-product-table {
    margin-bottom: 25px;
    border-collapse: collapse;
}

.wave-product-table, .wave-product-table td, .wave-product-table th {
    border: 1px solid #868686;
}

.wave-product-table-row {
}

.wave-product-table_point-cell {
    width: 120px;
    text-align: center;
}

.wave-product-table_point-cell--double {
    color: #C2004B;
}

.wave-product-table_vendorCode-cell {
    text-align: center;
}


@media (max-width: 786px) {
    .wave-products-banner__title {
        padding: 10px;
        font-size: 14px;
    }

    .wave-products-banner__title--red {
        padding: 10px;
        margin: 10px;
    }
}

.activate-code-page {
   /*padding-top: 140px;*/
}

.activate-code-page__title {
    margin-bottom: 20px;
}

.activate-code-page__body {
    /*display: flex;*/
}

.activate-code {
}

.activate-code-message {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 20px 0;
}
.activate-code-form {
    max-width: 500px;
}

.activate-code-message--error {
    color: #C2044B;
}

.activate-code-message--success {
    color: #63c44e;
}

@media (max-width: 1100px) {
    .activate-code {
        width: 100%;
    }
}

.order-details-page {
    /*padding-top: 140px;*/
}

.order-details-page__header {
    display: flex;
}

.order-details-page__body {
    margin-top: 60px;
}

.order-details-page__order-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: stretch;
    grid-gap: 20px;
    margin-bottom: 10px;
}
.order-details-page__order-items .order-item {
    /*margin-right: 25px;*/
}

.order-details-page__header > .order-details-page__header-left {

}

.order-details-page__header > .order-details-page__header-middle {
    flex-grow: 1;
}

.order-details-page__header > .order-details-page__header-right {
    text-align: right;
}

.order-details-page__header-title {
    display: flex;
    align-items: center;
    margin-left: -28px;
    margin-bottom: 10px;
}

.order-details-page__header-title-arrow {
    margin-right: 5px;
    border-color: #0074C2;
    cursor: pointer;
}

.order-details__date {
    font-size: 14px;
    font-weight: 600;
    color: #868686;
    margin-right: 5px;
}

.order-details__wrapper {
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
}

@media (max-width: 786px) {
    .order-details__date {
        font-size: 10px;
    }

    .order-details-page__body {
        margin-top: 20px;
    }

    .order-details-page__order-items {
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

}
.order-item {
}

.order-item__image img{
    border-radius: 10px;
    display: block;
    width: 100%;
}

.order-item__name {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;

}

@media (max-width: 786px) {
    .order-item__name {
        margin-top: 5px;
    }
}
.notification {
    padding: 10px 25px 10px 20px;
    border-radius: 5px;
    margin: 5px 0;
    position: relative;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em;
    text-align: justify;
}

.notification--warning {
    background-color: #ffde6f;
}

.notification__close {
    position: absolute;
    right: 5px;
    top: 5px;
}

.notification__close::after{
    display: inline-block;
    content: "\00d7";
    font-size: x-large;
    cursor: pointer;
    line-height: 16px;
}
@media (max-width: 786px) {
    .notification {
        font-size: 12px;
    }
}

