.cart-stick-panel{
    background-color: #ECECEC;
    padding: 30px 40px;
    border-radius: 5px;
    width: 300px;
}

.cart-stick-panel__title{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}

.cart-stick-panel__amount{
    font-size: 36px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 20px;
}

.cart-stick-panel__amount--error{
    color: #C2044B;
}

.cart-stick-panel__text{
  font-size: 16px;
    color: #C2044B;
    font-weight: 500;
    margin: 20px 0;
}

@media (max-width: 786px) {
    .cart-stick-panel {
        width: 100%;
    }
}