.text-input {
    width: 100%;
    height: 60px;
    position: relative;
    background-color: #ececec;
    border-radius: 5px;
    border: 1px solid white;
}

.text-input--error {
    border: 1px solid red;
}

.text-input--middle {
    height: 50px;
}


.text-input--middle > input {
    height: 25px !important;
    font-size: 16px !important;
}

.text-input--middle > .text-input__label--filled, .text-input--middle > .text-input__label--focus {
    font-size: 12px !important;
    height: 24px !important;
    line-height: 24px !important;
}

.text-input--middle > .text-input__label--cleared {
    font-size: 16px;
    height: 50px;
    line-height: 50px;
}

.text-input > input {
    height: 30px;
    padding: 0 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    width: 100%;
    bottom: 5px;
    outline: none;
    border: none;
    background-color: #ececec;
}

.text-input__label {
    width: 100%;
    position: absolute;
    font-weight: 500;
    color: #868686;
    padding: 0 20px;
    z-index: 50;
    transition: all 100ms;
    cursor: text;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-input__label--cleared {
    font-size: 16px;
    height: 60px;
    line-height: 60px;
}

.text-input__label--filled, .text-input__label--focus {
    font-size: 12px;
    height: 35px;
    line-height: 35px;
}

@media (max-width: 768px) {
    .text-input > input, .text-input__label {
        padding: 0 7px;
    }
    .text-input > input {
        height: 25px;
    }

}

@media (max-width: 320px) {
    .text-input > input {
        font-size: 12px;
    }

    .text-input__label--cleared {
        font-size: 10px;
    }

    .text-input__label--filled, .text-input__label--focus {
        font-size: 9px;
    }

}

@media (min-width: 321px) and (max-width: 768px) {
    .text-input > input {
        font-size: 14px;
        bottom: 2px;
    }

    .text-input {
        height: 40px;
    }

    .text-input__label--filled, .text-input__label--focus {
        font-size: 9px !important;
        height: 14px !important;
        line-height: 14px !important;
    }

    .text-input__label--cleared {
        font-size: 12px;
        height: 40px;
        line-height: 40px;
    }

}