.filter {
  display: flex;
  flex-wrap: wrap;
}

.filter__item {
  background-color: #ececec;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  margin-right: 10px;
  /*margin-bottom: 10px;*/
  cursor: pointer;
}

.filter__expander {
  position: relative;
}

.filter__expander-submenu {
  right: auto !important;
  top: 43px !important;
  cursor: pointer;
}

.filter__item:hover {
  background-color: #e2e2e2;
}

.filter__item--active {
  background-color: #3884be;
  color: white;
}

.filter__item--bold {
  background-color: #53B741;
  color: white;
}
.filter__item--bold:hover {
  background-color: #63c44e;
}

.filter__item--bold.filter__item--active:hover {
  background-color: #53B741;
}


.filter__item--active:hover {
  background-color: #3884be;
}


@media (max-width: 786px) {
  .filter__expander-submenu {
    right: 0 !important;
    top: 43px !important;
  }
}
