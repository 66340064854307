.form-row{
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

@media (min-width: 321px) and (max-width: 768px) {
    .form-row{
        margin-bottom: 10px;
    }
}