.header {
    background-color: white;
    display: flex;
    align-items: center;
}

.header__item {
    margin-left: 15px;
}

.header__item:first-child {
    margin-left: 0;
}

.header__item--separator {
    flex-grow: 1;
}

@media (max-width: 786px) {
    .header__item--mob-hide {
        display: none;
    }

    .header__item--mob-show {
        display: block;
    }

    .header__item {
        margin-left: 10px;
    }
}

@media (min-width: 786px) {
    .header__item--mob-show {
        display: none;
    }


}
