.product-detail-page {
  /*border: 5px solid red;*/
  display: flex;
  min-height: 100%;
  position: relative;
}

.product-detail-page__sidebar {
  /*border: 5px solid black;*/

  color: white;
  width: 35%;
}

.product-detail-page__description {
  /*border: 5px solid blue;*/
  font-size: 18px;
  padding: 0 50px 50px 100px;
  position: relative;
  width: 65%;
}

.product-detail-page__stick {
  /*border: 5px solid green;*/
  height: 100vh;
  position: sticky;
  top: 0px;
  width: 100%;
  background-size: cover;
  padding-top: 30px;
  background-position: bottom;
  background-repeat: no-repeat;
  overflow: hidden;
  min-height: 435px;
}

.product-detail-page__logo > img {
  /*display: block;*/
  max-width: 100%;
}

.product-detail-page__logo {
  margin-bottom: 40px;
  text-align: center;
}

.product-detail-page__face-value {
  margin-bottom: 20px;
}

.product-detail-page__stick-content {
  padding: 50px 35px 0;
}

.product-detail-page__description > h1 {
  font-size: 36px;
}

.product-detail-page__description > h2 {
  font-size: 24px;
}

.product-detail-page__description > h3 {
  font-size: 18px;
}

.product-detail-page__description > p {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

.product-detail-page__preorder-title{
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
}

.product-detail-page__preorder-button{
  margin-bottom: 15px;
}

.button--white{

}

@media (max-width: 950px) {
  .product-detail-page {
    flex-direction: column;
  }
  .product-detail-page__stick-content {
    padding: 0px 10px 0;
  }

  .product-detail-page__sidebar {
    width: 100%;
  }

  .product-detail-page__description {
    width: 100%;
    padding: 0 0 0 0;
  }

  .product-detail-page__stick {
    height: auto;
    background-position: bottom;
    padding-bottom: 50px;
  }
}
