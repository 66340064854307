.products-page {
  /*padding-top: 100px;*/
  /*border: 5px solid green;*/
}

.products-page__slider {
  /*margin-top: 20px;*/
  margin-bottom: 40px;
  border-radius: 10px;
  overflow: hidden;
}

.products-page__filter {
  margin-bottom: 40px;
}

.products-page__list {
  margin-bottom: 40px;
}

