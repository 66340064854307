.activate-code-page {
   /*padding-top: 140px;*/
}

.activate-code-page__title {
    margin-bottom: 20px;
}

.activate-code-page__body {
    /*display: flex;*/
}
